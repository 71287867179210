/*
  This file is supposed to contain only CSS required for "bootstrapping" the application
  
*/
@import 'safari-variables';
@import 'safari-fonts';
/* We have to figure out what we really need out of safari-bootstrap-imports*/
@import 'safari-bootstrap-imports';
@import 'safari-table';
@import 'safari-overrides';

/*=====  End of OVERRIDE VARIABLES FROM _variables.scss above  ======*/
/*=============================================
=            `GENERAL        =
=============================================*/
html {
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
:focus {
  outline: none !important;
}

//removes the "grip tape" from the bottom right of the text area that are only found in lpms pages
textarea.s_textarea {
  min-height: 31px;

  @include scroller;
}

/* High Res Display: Start*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased;
  }

  /* High Res Display:  End */
}

body {
  background-color: $paper;
}

ins {
  text-decoration: none;
}

.s_dev {
  color: $purple;
  font-family: 'courier', monospace;
  font-size: 0.9em;
  background: lighten($purple, 30%);
}

@include media-breakpoint-up(xl) {
  .s_container-wide {
    max-width: 1400px;
  }
}

@include media-breakpoint-down(sm) {
  .s_container-wide {
    max-width: 1400px;
  }
}

.s_error {
  display: block;
  white-space: pre-wrap;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: none;
  overflow-wrap: break-word;
  background: rgba(220, 70, 70, 0.15);
  padding: 4px 8px;
  margin-top: 1px;

  &.disabled {
    background: transparent;
    opacity: 1;

    &::placeholder {
      opacity: 1;
      color: $red;
    }
  }
}

/*=====  End of `BACKGROUND STYLES  ======*/

/*=============================================
=            NAVBAR            =
=============================================*/

.navbar {
  min-height: 60px;
}

@include media-breakpoint-down(xs) {
  .navbar > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Used to have invisible components trigger dismissal of loading screen */
.navbar--hidden.placeholder {
  display: none;
}

/*=====  End of FOLDER ACTIONS  ======*/
/*=============================================
=            `RESIZE COLUMN            =
=============================================*/
.s_rsz {
  section {
    padding: 0;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }

  .s_sec,
  .s_twy,
  .s_sec-body {
    margin-left: 4px;
    margin-right: 4px;
  }

  section.s_border-bot {
    border-bottom: 1px var(--brown) solid;
  }
}

.s_rsz .s_acc-title {
  margin-left: 15px;
}

.s_rsz .col.embed-responsive {
  min-height: calc(100vh - 100px);
}

@include media-breakpoint-up(sm) {
  /* horizontal panel*/
  .s_rsz {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* avoid browser level touch actions */
    touch-action: none;

    .s_rsz-left,
    .s_rsz-right {
      height: calc(100vh - 50px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 14px;
        height: 18px;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
        -webkit-box-shadow:
          inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }

      &::-webkit-scrollbar-thumb:vertical:hover {
        background-color: var(--brown);
        transition: ease-in-out;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }

  .s_rsz-split {
    z-index: 1000;
    flex: 0 0 auto;
    width: 15px;
    min-height: 200px;
    cursor: e-size;
    background: #b0a99b;
    margin-top: -10px;
    margin-bottom: -10px;
    box-shadow:
      inset -5px 0 5px #d7d2cc,
      -5px 0 10px #d7d2cc,
      inset 5px 0 5px #d7d2cc,
      5px 0 10px #d7d2cc;

    &:hover,
    &:active {
      background: url(../assets/img/s_rsz-handle.svg) center 40vh no-repeat #b0a99b;
    }
  }

  .s_rsz-right {
    flex: 1 1 auto;
    /* resizable */
    width: 100%;
    min-height: 200px;
    min-width: 200px;
    padding: 0 6px;
  }

  /* full height minus margin in modal */
  .s_rsz-left {
    background-color: $paper;
    flex: 0 0 auto;
    /* only manually resize */
    width: calc(30% - 25px);
    min-width: 50px;
  }

  @include media-breakpoint-down(lg) {
    .s_rsz-left {
      width: 40%;
    }
  }

  @include media-breakpoint-up(xl) {
    .s_rsz-left {
      width: calc(34% - 25px);
    }
  }
}

/*=====  End of `STATUS SELECTOR  ======*/

/*=============================================
=            TABLE TOPPER           =
=============================================*/

.s_tbl-nogap {
  margin-top: -15px;
}

.s_tbl-topper {
  padding: 0 8px;
  //border-top: 1px solid var(--brown-lite);
  margin-right: 0;
  margin-left: 0;
  background-color: $tan;

  .form-control {
    background-color: transparent;
    border-bottom: 1px solid var(--brown-lite);

    &:focus {
      background-color: $white;
    }
  }

  .form-group {
    margin-bottom: 0;
    padding: 0 0 0 15px;
  }

  .form-group:first-of-type {
    padding-left: 0;
  }
}

/*=====  End of PROMPT MODAL  ======*/

/*=============================================
=            `MODAL PROMPT            =
=============================================*/
.alert {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #faf5f2;

  .modal-header {
    padding: 0 0 6px;

    .modal-title {
      font-weight: 100;
      font-size: 1.6rem;
    }
  }

  .modal-body {
    padding: 20px 0;
  }

  .modal-footer {
    padding: 10px 0 0;
  }

  .btn {
    &.s_btn-confirmation-ok,
    &.s_btn-confirmation-cancel,
    &.s_btn-confirmation-aux1 {
      min-width: 80px;
    }
  }
}

/*=====  End of PROMPT MODAL  ======*/

/*=============================================
=            `DIALOG (GENERIC)           =
=============================================*/
.s_dia {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
  transition: all 0.15s ease;
}

.s_dia.show::before {
  /*----------  Page-screen prevents non-dialog input  ----------*/
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  cursor: default;
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}

/*=====  End of DIALOG  ======*/

/*=============================================
=            `MAIN          =
=============================================*/

main {
  width: 100%;
  padding: 0;
  min-height: 100vh;
}

.s_con-header {
  margin: 10px 0;
}

.s_con-title,
.s_dia-title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 32px;
}

@include media-breakpoint-down(sm) {
  .s_con {
    padding-right: 0;
    padding-left: 0;
  }

  main {
    min-width: 350px;
  }
}

.s_con-subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.s_selector {
  margin-bottom: 0;
  padding-bottom: 0;
  min-width: 200px;
  border: 0px;
  outline: 0px;
  background-color: transparent;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
}

.s_pipe {
  border-left: 1px $gray-500 solid;
  margin: auto 16px;
  height: 40px;
}

.s_pipe.float-left {
  /*  when adjacent to dropdown (i.e. table header page) */
  padding-left: 5px;
}

@include media-breakpoint-down(sm) {
  .s_con-title {
    padding-top: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}

/*=====  End of CONTENT  ======*/

/*=====  End of `PAGING  ======*/
/*=============================================
=            `ICONS            =
=============================================*/
button[class*='s_ico-']:not(.s_ico-no-transparent) {
  background: transparent;
}

[class*='s_ico-'] {
  &:hover {
    cursor: pointer;
  }

  border: none;

  &::before,
  &::after {
    font: 100 24px/24px 'safdings';
  }
}

.s_ico-bold::before,
.s_ico-bold::after {
  font-weight: bold;
}

/*----------  ICON - Array of all icons  ----------*/
$ico-array: $icons;

@each $ico-id in $ico-array {
  .s_ico-#{nth($ico-id, 1)},
  /*----------  Sidebar Icons  ----------*/
  #s_mnu-link-#{nth($ico-id, 1)}>ins {
    &::before {
      content: nth($ico-id, 2);
    }
  }

  .s_ico-embed:not(.collapsed) .s_ico-#{nth($ico-id, 1)},
  .s_ico-embed:hover .s_ico-#{nth($ico-id, 1)} {
    &_fill {
      color: $white;
      font-weight: 600;

      &::before {
        content: nth($ico-id, 3);
        color: var(--brown);
        // color: #bc9f6e; text-shadow: -2px -2px 5px rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.5);
        //
      }
    }
  }

  .s_ico-#{nth($ico-id, 1)}_fill {
    &::before {
      content: nth($ico-id, 2);
    }

    &:hover::before {
      content: nth($ico-id, 3);
    }

    &.disabled,
    &.disabled:hover {
      &::before {
        color: $gray-300 !important;
      }
    }
  }

  .s_over.s_ico-#{nth($ico-id, 1)} {
    &::before {
      content: nth($ico-id, 3);
    }
  }

  .s_ico-embed:not(.collapsed) .s_ico-#{nth($ico-id, 1)},
  .s_ico-embed:hover .s_ico-#{nth($ico-id, 1)} {
    &_fill {
      &::before {
        content: nth($ico-id, 3);
      }
    }
  }

  /*----------  ICON - Toggle (Click solid background on/off)  ----------*/
  .s_ico-toggle input {
    display: none;
  }

  /*----------  ICON - Fill with solid background  ----------*/
  input:checked ~ .s_ico-#{nth($ico-id, 1)}::before {
    content: nth($ico-id, 2);
  }
}

/*----------  ICON - Lock  ----------*/
.s_ico-user,
.s_ico-talk,
.s_ico-link {
  &_fill div {
    line-height: 0;
    padding-top: 12px;
    font-size: 11px;
    cursor: pointer;
  }
}

.s_lpo {
  .s_ico-lock_fill div {
    line-height: 0;
    padding-top: 20px;
  }
}

/*----------  ICON - Embed (Add text inside icon)  ----------*/
.s_ico-embed {
  text-align: center;
  font-size: 0.8rem;
}

.s_ico-embed ins {
  line-height: 30px;
  float: left;
  margin-right: 8px;
  width: 28px;
}

.s_ico-embed ins::before {
  line-height: 32px;
  margin-right: -1.7rem;
  float: left;
  font-size: 28px;
}

.s_sec .s_ico-embed ins::before {
  font-size: 32px;
}

.s_sec .s_ico {
  height: 32px;

  ins::before {
    font-size: 32px;
    color: var(--brown) !important;
  }
}

.s_ico-embed .s_ico-folder,
.s_ico-embed .s_ico-file {
  line-height: 32px;
  display: block;

  &_fill {
    line-height: 32px;
  }
}

/*----------  ICON - Size  ----------*/
.s_ico-md::before,
.s_ico-md::after {
  font-size: 16px;
  line-height: 16px;
}

.s_ico-sm::before,
.s_ico-sm::after {
  font-size: 14px;
  line-height: 14px;
}

.s_ico-xs::before,
.s_ico-xs::after {
  font-size: 12px;
  line-height: 12px;
}

/* This is the paperclip icon in correspondence listing, indicating attachments */
.s_ico-attachment {
  &::before {
    content: '\e505';
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    margin-top: -2px;
  }

  span {
    margin-left: 20px;
  }
}

/*----------  ICON - Customization  ----------*/
.s_ico-expand_fill,
.s_ico-collapse_fill {
  &::before {
    line-height: 16px;
  }
}

.s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $red !important;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.disabled > .s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-ungarbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $orange !important;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.disabled > .s_ico-garbage {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-close {
  &_fill,
  &_fill:hover {
    &::before {
      color: $red-brite !important;
      cursor: pointer;
      font-size: 28px;
    }
  }

  &_fill.disabled,
  &_fill.disabled:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-remove_fill {
  &::before {
    font-size: 14px;
    font-weight: bold;
    color: var(--brown) !important;
  }

  &:hover {
    &::before {
      color: $red-brite !important;
      cursor: pointer;
    }
  }
}

.disabled > .s_ico-remove {
  &_fill,
  &_fill:hover {
    &::before {
      color: $gray-800 !important;
    }
  }
}

.s_ico-grip_fill {
  cursor: n-resize;
}

/*----------  ICON - Star  ----------*/

.s_ico-star_toggle:hover,
.s_ico-star_toggle:active {
  cursor: pointer;
}

.s_ico-star_toggle::after {
  content: '\e103';
  color: var(--brown);
  font-size: 28px;
  line-height: 30px;
}

.s_lpo-header {
  .s_ico-star_toggle::after {
    line-height: 32px;
    font-size: 28px;
    color: $gold;
  }

  input:checked ~ .s_ico-star_toggle::before {
    font-size: 28px;
    line-height: 32px;
    margin-right: -28px;
  }
}

.s_ico-star_toggle:hover::before,
input:checked ~ .s_ico-star_toggle::before {
  color: $gold;
}

.s_ico-star_toggle:hover::before,
input:checked ~ .s_ico-star_toggle::before {
  content: '\e104';
  font-size: 28px;
  line-height: 30px;
  margin-right: -28px;
}

.s_ico-star_toggle:hover::after,
input:checked ~ .s_ico-star_toggle::after {
  color: $gold;
}

/*----------  ICON - Mini icons, for example used as inline links near alerts  ----------*/
ins.s_ico.s_ico-mini::before {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
}

/*=====  End of ICONS  ======*/
/*=============================================
=            `SIDEBAR            =
=============================================*/
// :host is broken because we turned off style isolation to get some of these styles to apply to the header.
// We should fix that later, but not right before a demo!
//:host {
sl-bootstrap-navbar,
#sidebar {
  background-image: var(--navbar-background-image);
  background-color: var(--navbar-background-color);
  background-position: top right;
}

#sidebar {
  padding-right: 18px;

  .nav-item {
    font-weight: 600;
  }

  ins {
    font: 100 18px 'safdings';
    float: left;
    margin-right: 6px;
    margin-top: 2px;
  }

  /*=====  Active is when user collapses sidebar to a narrow column (to icons only)  ======*/
  &.active {
    min-width: 60px;
    max-width: 60px;

    .sidebar-header .s_brand-collapsed {
      display: block;
      text-align: center;
    }

    .sidebar-header .s_brand-expanded {
      display: none;
    }

    .nav-link {
      .badge {
        margin-right: -4px;
        margin-top: 2px;
      }

      @include media-breakpoint-up(lg) {
        &:hover .badge {
          margin-top: -20px;
          margin-right: 0;
        }
      }
    }

    .mru-intake-portals {
      display: none;
    }

    /*=====  End ACTIVE ======*/
  }

  .sidebar-header .s_brand-collapsed {
    display: none;
  }

  .sidebar-header .s_brand-expanded {
    cursor: pointer;
    height: 68px;
    margin-right: 38px;
    content: url(../assets/img/s_wordmark.svg);
  }

  .sidebar-header .s_brand-collapsed {
    height: 60px;
    cursor: pointer;
    content: url(../assets/img/s_wordmark.svg);
    clip-path: inset(0 128px 0 0);
  }

  .dropdown-toggle,
  .nav-subitem,
  .nav-link {
    padding: 14px 10px;
    display: block;
    color: var(--navbar-item-color);
    border-left: 2px transparent solid;
    font-size: 0.9rem;
    height: 54px;
    line-height: 24px;

    &.active,
    &:hover {
      color: $gray-200;
    }

    &[data-toggle='collapse'] {
      position: relative;
    }

    .badge {
      margin-top: 4px;
      min-width: 30px;
    }

    .badge-light {
      background: transparent;
      border: 1px solid $gold;
      color: $gold;
    }

    &:hover .badge,
    &.active .badge {
      border-color: $gray-200;
      color: $white;
    }

    .badge-danger,
    &:hover .badge-danger,
    &.active .badge-danger {
      color: $black;
      border: 1px solid lighten($danger, 10%);
      background: lighten($danger, 10%);
    }
  }

  .nav-subitem {
    background: $black;
    padding-left: 32px;

    &.active {
      border-left: 2px $white solid;
    }
  }

  .mru-intake-portals {
    padding-top: 0px;

    li .nav-subitem {
      font-size: 0.7rem;
      max-width: 199px;
      white-space: normal;
      height: auto;
      line-height: 12px;
      padding: 6px 10px 6px 10px;

      &.active {
        border-left: 2px $white solid;
      }
    }

    li:first-of-type .nav-subitem {
      padding-top: 12px;
    }

    li:last-of-type .nav-subitem {
      padding-bottom: 12px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  /*=====  Black arrow above the expended subsection ======*/
  &:not(.active) .dropdown-toggle:not(.collapsed)::before {
    position: absolute;
    left: 15px;
    bottom: 0;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--brown-drk);
    border-left: 9px solid transparent;
    content: '';
    margin-top: -9px;
  }

  .s_mnu-header {
    padding: 10px;
    color: var(--brown-lite);
    font-size: 0.7rem;
  }

  .s_mnu-company {
    padding-left: 12px;
  }

  .s_mnu-hr {
    hr {
      margin: 0 10px;
      border-top: 1px $gold-drk solid;
    }

    &:first-of-type {
      hr {
        border-top-width: 3px;
        border-top-style: double;
        border-top-color: var(--navbar-main-divider-color);
      }
    }
  }

  .s_sub-hr {
    background: $black;
    margin: 0px;

    hr {
      margin: 0 10px;
      border-color: var(--brown);
    }
  }
}

@include media-breakpoint-up(lg) {
  #sidebar {
    /* Browser Mode */
    &.active .nav-item,
    &.active .s_mnu-header {
      display: none;
    }

    &.active ul.show {
      display: none;
    }

    &.active > ul > li:not(.s_mnu-hr):hover {
      position: relative;
      z-index: 10000;
      display: block;
      width: 250px;
      text-align: left;
      background: var(--brown-mid);
    }

    &.active ul li:hover a .nav-item {
      display: block;
    }

    &.active > ul > li:hover ul {
      left: 50px;
      position: absolute;
      display: block;
      width: 200px;
    }
  }

  main .navbar #sidebarHide {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  /* Phone Mode */
  #sidebar {
    display: none;

    &.active {
      display: block;
      min-width: 210px;
      max-width: 210px;
      text-align: left;
      height: 100vh;
    }

    &.active .sidebar-header .s_brand-expanded {
      display: block;
    }

    &.active .sidebar-header .s_brand-collapsed {
      display: none;
    }

    #sidebarCollapse span {
      display: none;
    }
  }

  .navbar-toggler {
    display: inline-block;
    position: relative;
    padding: 0px !important;
    margin-right: 16px;

    &:active,
    &:focus {
      outline: 0;
    }

    span {
      display: block;
      background-color: var(--brown);
      height: 2px;
      width: 28px;
      margin: 6px 0;
      transform: rotate(0deg);
      position: relative;
      left: 0;
      opacity: 1;
    }

    span:nth-child(1),
    span:nth-child(3) {
      transition: transform 0.35s ease-in-out;
    }

    &:not(.collapsed) {
      margin-right: 24px;
      margin-left: -22px;

      span:nth-child(1) {
        position: absolute;
        left: 12px;
        top: 10px;
        transform: rotate(135deg);
        opacity: 0.9;
      }
      span:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }
      span:nth-child(3) {
        position: absolute;
        left: 12px;
        top: 10px;
        transform: rotate(-135deg);
        opacity: 0.9;
      }
    }
  }
}
.hidden-viewport {
  display: block;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
.link-button {
  @extend .btn;
  @extend .btn-link;
  @extend .align-baseline;
  display: inline;
  padding: 0px;
  margin: 0px;
}
@include media-breakpoint-up(md) {
  .s_edit,
  .s_save,
  .s_cancel,
  .s_delete {
    min-width: 80px;
  }
}

.s_cancel {
  margin-left: 4px;
}

.s_delete {
  margin-left: 12px;

  &::before {
    background: darken($gray-400, 10%);
    display: inline-block;
    width: 1px;
    content: '';
    height: 32px;
    margin-left: -1.4rem;
    position: absolute;
    top: 0;
  }
}

/*=============================================
=            `CLIENT THEMES                   =
=============================================*/
:root {
  --navbar-background-color: #{var(--brown)};
  --navbar-background-image: url(../assets/img/s_bg-sidebar.jpg);
  --navbar-main-divider-color: #{$gold-drk};
  --navbar-item-color: #{$gold};
}

/*=====  End of `CLIENT THEMES ======*/
