@import 'safari-variables';
section.list-loading {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: none;
  min-height: 300px;
}
.filter {
  padding: 8px;
  margin: 15px auto;
  width: 30%;
}
.datatable-header-cell-template-wrap,
.table td,
.table th,
.table thead th {
  border-width: 0;
  min-width: 50px;
}
.ngx-datatable .s_bot,
.table .s_bot {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.datatable-header-cell-template-wrap,
.table th {
  color: $gray-600;
  white-space: nowrap;
}

.s_sticky {
  position: sticky;
  top: 0;
}
.s_zeb tr:nth-child(4n-1),
.s_zeb tr:nth-child(4n),
.s_zeb tr.s_mid:nth-child(even) {
  background-color: #f4f3ef;
}
/*----------  TABLE SORTER  ----------*/

.tablesorter-childrow,
#document-participant-list {
  //border-top: 1px var(--brown-lite) dotted;
}
.table thead {
  border-bottom: 1px var(--brown-lite) solid;
  border-top: 1px var(--brown-lite) solid;
}

.tablesorter-childrow > .tablesorter-childcell {
  height: 1px;
  padding: 0;
}
/*----------  SPACING  ----------*/
.ngx-datatable,
.table {
  /* top of stacked row */
  .s_top {
    padding-top: 6px;
    padding-bottom: 0;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* bottom of stacked row */
  .s_bot {
    padding-bottom: 6px;
    padding-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .s_span {
    padding: 6px 0;
  }
  .s_span-link {
    padding: 0 10px;
    line-height: 32px;
    vertical-align: top;
    min-height: 50px;

    a {
      vertical-align: middle;
    }

    &:hover,
    &:active {
      background: $gray-100;
      cursor: pointer;
    }
  }

  &.s_datatable-single-stack {
    // TODO #6479
  }
}
.table .s_mid {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: top;
}

/*----------  HEADER  ----------*/
.table thead {
  background: $tope-lite;
}
.datatable-header {
  background: $tan;
  //border-bottom: 1px var(--brown-lite) dotted;
}
.datatable-header-cell-template-wrap,
.table thead th {
  background-color: transparent;
  // background-color: lighten($tope, 4%);
  font-weight: 600;
  padding-left: 10px;
}
.datatable-body-cell {
  padding-left: 10px;
}
.datatable-header-cell-template-wrap .s_top,
.datatable-header-cell-template-wrap .s_top .s_span,
.table thead .s_top,
.table thead .s_top.s_span {
  padding-top: 5px;
  padding-bottom: 0;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*----------  SORT  ----------*/
.ngx-datatable .s_sort,
.table .s_sort {
  cursor: pointer;
  color: $blue;
}
.ngx-datatable .s_sort:hover,
.s_sort:hover {
  text-decoration: underline;
}

.s_ascend:after,
.s_descend:after {
  font-family: 'safdings';
  display: inline;
  color: $gray-700;
  font-size: 0.9em;
}

.s_ascend:after {
  content: '\e107';
}

.s_descend:after {
  content: '\e108';
}

/*----------  SPAN  ----------*/

tbody > tr > .s_span {
  white-space: normal;
}
.datatable-header-cell-template-wrap .s_span,
thead .s_span {
  vertical-align: top;
}

/*----------  STYLING  ----------*/
.datatable-header-cell-template-wrap,
.table {
  tr > th.s_divider,
  tr > td.s_divider {
    border-left: 1px var(--brown) solid;
  }
}

.datatable-row-odd {
  background: rgba(100, 85, 79, 0.06);
}
.empty-row {
  padding: 8px;
  font-size: 0.9em;
  color: $gray-600;
}
/*----------  EXPANDO  ----------*/
// Don't interfere with CKEditor dialogs

table:not(.cke_dialog) {
  tr th:not(.s_exp),
  tr td:not(.s_exp) {
    /* sets all columns with a explicit width except for the expando column which is null width and therefore acts as an accordion/expando--since the table is set to 100% width. This solution is better than setting the column width to 100% because it doesn't cause columns to jump and expand as a user drills into the nested tables. If you need to define a cell width then use min-width or max-width  */
    width: 1em;
    white-space: nowrap;
  }
}
.s_exp {
  /* reduces expando width to 2px since it's usually empty */
  padding-right: 2px;
  padding-left: 10px;
}
/*----------  TRUNCATE  ----------*/
.s_tnc,
.nav-subitem {
  /* clips object names (which are usually hyperlinked) if they're too long; can also be applied to a span to clip plain text within a table cell  */
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  line-height: normal;
}

[class*='s_tnc-'] {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s_tnc-1 {
  -webkit-line-clamp: 1;
}

.s_tnc-2 {
  -webkit-line-clamp: 2;
}

.s_tnc-3 {
  -webkit-line-clamp: 3;
}

// .nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

.ngx-datatable {
  .datatable-footer {
    //border-top: 1px dotted var(--brown-lite);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);

    .page-count {
      line-height: 50px;
      height: 50px;
      padding-left: 8px;
    }
    .datatable-pager {
      margin: 0 10px;

      li {
        vertical-align: middle;
        &.disabled,
        &[disabled] {
          a {
            color: rgba(0, 0, 0, 0.26) !important;
            background-color: transparent !important;
          }
        }
        &.active a {
          background-color: rgba(158, 158, 158, 0.2);
          font-weight: bold;
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom;

        &:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: rgba(158, 158, 158, 0.2);
        }
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px;
      }
    }
  }
  .datatable-summary-row {
    .datatable-body-row {
      background-color: #ddd;

      &:hover {
        background-color: #ddd;
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}

/*=============================================
=            NOTES            =
=============================================*/
// Selects the entire notes section
.s_notes {
  .ck.ck-content p {
    max-width: 800px;
  }
  .s_note-top {
    position: relative;
  }
  /* Header */
  .s_note-header-date,
  .s_note-header-author {
    display: block;
    float: left;
    padding-top: 0;
    line-height: 36px;
  }
  /* Content */
  .s_note-date,
  .s_note-author {
    display: block;
    float: left;
    color: var(--brown);
    font-weight: 600;

    line-height: 32px;
  }
  /* Column configuration */
  .s_note-header-author,
  .s_note-author {
    width: 200px;
    font-size: 1em;
  }
  .s_note-header-date,
  .s_note-date {
    width: 180px;
  }
  /* General display styling */
  .s_note-remove {
    float: right;
    position: absolute;
    right: 0;
    .s_ico-garbage_fill::before {
      line-height: 32px;
    }
    .s_ico-ungarbage_fill::before {
      line-height: 32px;
    }
  }
  .s_note-body {
    margin-left: 24px;
    font-size: 0.9em;
    padding-bottom: 8px;
    max-width: 800px;
    p {
      margin-top: 0.6rem;
      margin-bottom: 0;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  .s_ico-expand_fill,
  .s_ico-collapse_fill {
    &::before {
      line-height: 36px;
    }
  }
}

/*=====  End of NOTES  ======*/

/*=============================================
=            EMBEDDED ICONS            =
=============================================*/

.ngx-datatable .s_ico-embed {
  display: block;
  height: 32px;
  cursor: pointer;
}
.ngx-datatable {
  [class*='s_ico-']::before {
    cursor: pointer;
    color: $link-color;
  }
}
/*=====  End of EMBEDDED ICONS  ======*/
